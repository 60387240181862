import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCannabis,
	faHeart,
	faLongArrowAltRight,
	faRandom,
	faVest,
	faYinYang,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const services = [
	{
		icon: faHeart,
		title: "Web Development",
		description:
			"We design and develop custom websites optimized for your business needs and growth through strategic functionality and compelling user experience.",
	},
	{
		icon: faCannabis,
		title: "Mobile Apps",
		description:
			"We design and develop custom websites optimized for your business needs and growth through strategic functionality and compelling user experience.",
	},
	{
		icon: faRandom,
		title: "Custom Software",
		description:
			"For specialized requirements, we analyze your business processes, design tailored back-end solutions and integrate them with relevant interfaces using appropriate programming languages and databases.			",
	},
	{
		icon: faYinYang,
		title: "Software Design",
		description:
			"From concept to prototype, we collaborate closely to envision digital products aligned with your objectives and transform ideas into aesthetically pleasing and intuitive solutions.			",
	},
	{
		icon: faVest,
		title: "SEO",
		description:
			"Optimize your online presence through on-page improvements, content marketing and link building strategies handled by our in-house SEO experts to boost search visibility and traffic.			",
	},
	{
		icon: faRandom,
		title: "Devops",
		description:
			"Ensure seamless software delivery and operations through our proven implementation of continuous integration, infrastructure automation, monitoring and security practices.			",
	},
];

const ServiceItem = ({ service }) => (
	<div className="bg-gray-100 dark:bg-slate-800 hover:shadow-lg dark:hover:shadow-lg hover:-translate-y-1 h-full p-6 transition duration-500">
		<div className="pt-6 p-4">
			<FontAwesomeIcon icon={service.icon} className="text-[40px]" />
		</div>
		<div className="p-4">
			<h5 className="text-lg font-semibold mb-4">{service.title}</h5>
			<p className="opacity-70 mb-6">{service.description}</p>
			<NavLink
				to="/contact-us"
				className="text-sm font-semibold hover:text-blue-600 duration-300"
			>
				LEARN MORE{" "}
				<FontAwesomeIcon icon={faLongArrowAltRight} className="ml-1" />
			</NavLink>
		</div>
	</div>
);

ServiceItem.propTypes = {
	service: PropTypes.object.isRequired,
};

const Service = () => {
	return (
		<section className="py-14 md:py-24 bg-[#f2d4ff] dark:bg-[#0b1727] text-zinc-900 dark:text-white">
			<div className="container px-4">
				<div className="grid grid-cols-12">
					<div className="col-span-12 mb-12 text-center">
						<h3 className="text-3xl leading-none font-bold md:text-[45px]">
							Our Services
						</h3>
						
					</div>
				</div>

				<div className="grid grid-cols-12 gap-6">
					{services.map((service, i) => (
						<div className="col-span-12 md:col-span-6 xl:col-span-4" key={i}>
							<ServiceItem service={service} />
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Service;


// import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import AppRoutes from './AppRoutes.js';
import Navigation from "./components/Navigation";

const App = () => {
    return (
        <div className="App">
            <Navigation />
            <AppRoutes />
        </div>
    );
};



export default App;

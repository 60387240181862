import { NavLink } from "react-router-dom";



const portfolioList = [
	{
		image: "/images/KYC.png",
		title: "Sample Agency site",
		href: "https://agency-website-etq.pages.dev/#",
		categories: ["UI/UX Design"],
	},
	{
		image: "/images/website-design.png",
		title: "Web Portal Dev",
		href: "https://business-template.pages.dev/#",
		categories: ["UI/UX Design"],
	},
	{
		image: "/images/multi-page.png",
		title: "React Business template",
		href: "https://multi-page-consulatancy.pages.dev/",
		categories: ["Web Project"],
	},
	{
		image: "/images/ecommerce.png",
		title: "E Commerce Site",
		href: "https://ecommerce-template.pages.dev/",
		categories: ["Web Portal"],
	}
];

const OurWork = () => {
	return (
		<section className="ezy__portfolio5_oVxGjcPz py-14 md:py-24 bg-white dark:bg-[#0b1727] text-black text-opacity-80 dark:text-white">
			<div className="container px-4 mx-auto">
				<div className="grid grid-cols-12 justify-center mb-6 md:mb-12">
					<div className="col-span-12 lg:col-span-6 lg:col-start-4 text-center">
						<h2 className="text-3xl md:text-[45px] font-bold mb-6">
							Our Past Works
						</h2>
					</div>
					{/* <div className="col-span-12 text-center mt-6">
						{categories.map((category, i) => (
							<button
								onClick={() => (category.isActive = !category.isActive)}
								className={
									category.isActive
										? "py-2 px-5 mx-1 text-sm bg-blue-600 text-white rounded"
										: "py-2 px-5 mx-1 text-sm text-blue-600 rounded hover:bg-blue-600 hover:text-white duration-300"
								}
								key={i}
							>
								{category.label}
							</button>
						))}
					</div> */}
				</div>

				<div className="grid grid-cols-12 gap-6">
					{portfolioList.map((portfolio, i) => (
						
							<div
							className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3"
							key={i}
						>
							<a href={portfolio.href} target="_new">
								<div className="rounded-xl overflow-hidden duration-300 relative hover:-translate-y-1">
									<img
										src={portfolio.image}
										alt={portfolio.title}
										className="h-auto w-full"
									/>
									<div className="absolute top-0 left-0 right-0 bottom-0 rounded-xl bg-white dark:bg-black bg-opacity-70 dark:bg-opacity-70 backdrop-blur scale-90 opacity-0 flex flex-col justify-center items-center transition duration-300 text-center hover:scale-100 hover:opacity-100 p-4">
										<h5 className="text-xl font-medium mb-2">
											{portfolio.title}
										</h5>
										<p className="mb-0">{portfolio.categories.join(", ")}</p>
									</div>
								</div>
							</a>
						</div>
						
						
					))}
					<div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
						<button className="bg-blue-600 hover:bg-opacity-90 text-white border border-blue-600 py-3 px-7 rounded transition font-normal">
							<NavLink to={'/contact-us'}>
								Contact US for more details.
							</NavLink>
						</button>
					</div>
					
				</div>
			</div>
		</section>
	);
};

export default OurWork;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowRight,
	faClock,
	faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const contents = [
	{
		jobType: "Remote friendly",
		location: "Remote",
		title: "Product Designer",
		duration: "Full Time",
		tag: "Design",
		details: "We're looking for a mid level product designer to join our team.",
	},
	{
		jobType: "Remote friendly",
		location: "Remote",
		title: "UI/UX Designer",
		duration: "Part-time",
		tag: "Design",
		details: "We're looking for a mid level product designer to join our team.",
	},

	{
		jobType: "Remote friendly",
		location: "Remote",
		title: "React JS Developer",
		duration: "Full Time",
		tag: "Developer",
		details: "We're looking for a mid level product designer to join our team.",
	},
	{
		jobType: "Remote friendly",
		location: "Remote",
		title: "Product Designer",
		duration: "Part Time",
		tag: "Developer",
		details: "We're looking for a mid level product designer to join our team.",
	},
];

const CareerCard = ({ content }) => {
	const { jobType, tag, details, location, title, duration } = content;
	return (
		<div className="bg-white dark:bg-[#1E2735] border dark:border-[#1E2735] shadow-sm rounded-xl w-full p-4 md:p-6 mt-6">
			<div className="grid grid-cols-12">
				<div className="col-span-12 lg:col-span-8">
					<div className="">
						<h4 className="inline-flex text-2xl font-medium mb-4">{title}</h4>
						<p className="py-1 px-3 w-48 inline-flex items-center justify-center rounded-2xl bg-gray-400 dark:bg-gray-600 bg-opacity-20 dark:bg-opacity-20 ml-2 mb-2">
							{tag}
						</p>
						<p className="opacity-75 mb-3">{details}</p>
						<div className="flex items-center mb-2">
							<div className="flex items-center mr-2 sm:mr-6">
								<FontAwesomeIcon
									icon={faMapMarkerAlt}
									className="mr-2 opacity-75"
								/>
								<p className="opacity-75">{jobType}</p>
							</div>
							<div className="flex items-center mr-2 sm:mr-6">
								<FontAwesomeIcon icon={faClock} className="mr-2 opacity-75" />
								<p className="opacity-75">{duration}</p>
							</div>
						</div>
					</div>
				</div>
				<div className="col-span-12 lg:col-span-4 mt-6 lg:mt-0">
					<div className="flex flex-col lg:items-center">
						<p className="py-1 px-3 w-48 flex items-center justify-center rounded-2xl bg-gray-400 dark:bg-gray-600 bg-opacity-20 dark:bg-opacity-20 mb-4">
							{location}
						</p>
						<div>
							<button className="font-medium hover:text-blue-600 transition p-0 ml-2 lg:ml-0">
								Apply Now{" "}
								<FontAwesomeIcon icon={faArrowRight} className="ml-1" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

CareerCard.propTypes = {
	content: PropTypes.object.isRequired,
};

const Careers = () => {
	return (
		<section className="py-14 md:py-24 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white">
			<div className="container px-4">
				<div className="grid grid-cols-12">
					<div className="col-span-12 md:col-span-10 lg:col-span-8 xl:col-span-6">
						<div>
							<h1 className="text-[32px] font-bold capitalize lg:text-[52px] mt-6 mb-4">
								Start Doing Works That Matters
							</h1>
							<p className="text-lg font-medium leading-normal lg:text-xl lg:pr-5">
								We are looking for passionate people to join us on our mission.
								We value flat hierachies, clear communication, full ownership
								and responsibility.
							</p>
						</div>
					</div>
					<div className="col-span-12">
						<hr className="mt-12 mb-6 mx-2 dark:border-gray-700" />
					</div>

					<div className="col-span-12">
						<div className="grid grid-cols-12">
							<div className="col-span-12 md:col-span-4">
								<div className="mt-6">
									<h3 className="text-3xl font-medium mb-2">Design</h3>
									<p className="opacity-75">
										Open positions in our design team
									</p>
								</div>
							</div>
							<div className="col-span-12 md:col-span-8">
								{contents.slice(0, 2).map((content, i) => (
									<CareerCard content={content} key={i} />
								))}
							</div>
						</div>
					</div>

					<div className="col-span-12">
						<hr className="mt-12 mb-6 mx-2 dark:border-gray-700" />
					</div>

					<div className="col-span-12">
						<div className="grid grid-cols-12">
							<div className="col-span-12 md:col-span-4">
								<div className="mt-6">
									<h3 className="text-3xl font-medium mb-2">Developer</h3>
									<p className="opacity-75">
										Open positions in our developer team
									</p>
								</div>
							</div>
							<div className="col-span-12 md:col-span-8">
								{contents.slice(2, 4).map((content, i) => (
									<CareerCard content={content} key={i} />
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Careers;


import React from "react";
import { Routes, Route } from "react-router-dom";

import Index from "./pages/Index.js";
import Careers from "./components/Careers";
import ContactUs from "./components/ContactUs.js";
import FaqComponent from "./components/FaqComponent.js";
import OurWork from "./components/OurWork.js";


const AppRoutes = () => {
    return (
        <Routes>
            <Route exact path="/" element={<Index />} />
            <Route exact path="/careers" element={<Careers />} />
            <Route exact path="/index" element={<Index />} />
            <Route exact path="/our-work" element={<OurWork />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
            <Route exact path="/faq" element={<FaqComponent />} />
        </Routes>
    );
};

export default AppRoutes;
